import styled from 'styled-components'

export const StyledWrapper = styled.div`
  background-color: var(--brand-primary-yellow-bg1-color);

  .quality_story_title {
    color: var(--brand-primary-green-color);
  }

  .quality_wrapper {
    display: flex;
    align-items: center;
  }
  .quality_wrapper svg {
    width: 15px;
    height: 10px;
    object-fit: contain;
    margin-right: 5px;
  }

  .quality_wrapper div {
    font-family: var(--brand-font-family-text);
    font-weight: var(--brand-font-weight-400);
    font-size: 14px;
    margin-top: 8px;
  }

  @media (min-width: 768px) {
    div {
      margin: 0;
    }
    .quality_container {
      margin-top: 20px;
      font-size: 20px;
    }
    .quality_wrapper svg {
      height: 20px;
      align-self: center;
      margin-top: 15px;
    }
    .quality_wrapper div {
      align-self: center;
      font-size: 20px;
    }

    .quality_story_container {
      flex-direction: row-reverse;
    }
  }
`
