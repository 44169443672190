import isEmpty from 'lodash/isEmpty'

import ArrowLeft from '../../../../assets/icons/ArrowLeftBlack.svg'
import ArrowRight from '../../../../assets/icons/ArrowRightBlack.svg'
import {withCustomErrorBoundary} from '../../../../utils/withCustomErrorBoundary'
import {LargeRoundedBtn} from '../../../common/Button'
import CustomCarousel from '../../../common/CustomCarousel'
import OptimizedImage from '../../../common/OptimizedImage'
import {StyledWrapper} from './styles'

const Gallery = (props) => {
  const {
    titledImage = '',
    ctaLink = '',
    images = [],
    buttonText = 'Follow Us',
  } = props.state || {}

  if (isEmpty(images)) {
    return null
  }

  return (
    <StyledWrapper>
      <div className="gallery_top_wrapper">
        <div className="gallery_container">
          {/* TODO: IMAGE */}

          <OptimizedImage
            source={titledImage}
            aspectWidth="836"
            aspectHeight="350"
            desktopViewWidth="FULL"
            mobileViewWidth="FULL"
            altText="main titled pic"
            loading="lazy"
            customClassName="gallery_main_image"
          />

          <div className="gallery_carousel_wrap">
            <CustomCarousel
              iconLeft={ArrowLeft}
              iconRight={ArrowRight}
              scrollWidth={375}
              scrollDuration={100}
            >
              {images.map((image, index) => {
                const {
                  image: imageUrl = '',
                  postLink = '',
                  altText = 'instagram pic',
                } = image || {}
                return (
                  <div className="gallery_list_item" key={`gallery-${index}`}>
                    <a href={postLink}>
                      <OptimizedImage
                        source={imageUrl}
                        aspectWidth="360"
                        aspectHeight="500"
                        desktopViewWidth="FULL"
                        mobileViewWidth="FULL"
                        altText={altText}
                        loading="lazy"
                        customClassName="gallery_list_item_image"
                      />
                    </a>
                  </div>
                )
              })}
            </CustomCarousel>
          </div>
        </div>
      </div>
      <LargeRoundedBtn
        text={buttonText}
        link={ctaLink}
        target="_blank"
        rel="noopener noreferrer"
        className="gallery_btn"
      />
    </StyledWrapper>
  )
}

export default withCustomErrorBoundary(Gallery, 'Gallery')
