import styled from 'styled-components'

export const StyledReviews = styled.div`
  padding: 0 12px 0;
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 30px;

  .container {
    width: 100%;
  }

  .image-gallery-slides {
    border-radius: 20px;
  }

  .carousel-mobile-nav {
    width: 36px;
    height: 36px;
    border: none;
    border-radius: 50%;
    background: var(--brand-primary-black-color);
    position: absolute;
    top: 30%;
    z-index: 2;

    &:focus,
    &:active {
      outline: none;
    }

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }
  }

  .carousel_dots {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;

    .carousel_dot {
      margin: 0 4px 0 5px;

      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: var(--brand-primary-black-color);

      &[data-isactive='true'] {
        width: 12px;
        height: 12px;
        background-color: #ffc820;
      }
    }
  }

  @media (min-width: 768px) {
    padding-top: 30px;
    margin-bottom: 60px;

    .container {
      max-width: 1110px;
      display: flex;
      flex-direction: column;
    }

    .carousel-mobile-nav {
      top: 45%;
      width: 60px;
      height: 60px;
      font-size: 18px;

      &.left {
        left: -30px;
      }
      &.right {
        right: -30px;
      }
    }

    .carousel_dots {
      margin-top: 60px;

      .carousel_dot {
        width: 10px;
        height: 10px;

        &[data-isactive='true'] {
          width: 20px;
          height: 20px;
          background-color: #ffc820;
        }
      }
    }
  }
`
export const StyledReview = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 14px;
  background: #ffebad;
  --character-background: #ffebad;

  border-radius: 20px;
  .review-image {
    width: 100%;
    height: auto;
    border-radius: 20px;
  }

  .left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 6px;
  }

  .review-title {
    font-weight: var(--brand-font-weight-bold);
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.25px;
    margin: 7px 0 6px;
  }

  .review-body {
    font-weight: var(--brand-font-weight-normal);
    font-size: 14px;
    line-height: 160%;
    letter-spacing: 0.2px;
    margin: 0 0 16px;
    color: #6c6c6c;
    max-width: 100%;
    white-space: normal;
    text-align: left;
  }

  .review-footer {
    font-weight: var(--brand-font-weight-medium);
    font-size: 16px;
    line-height: 18px;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    width: 100%;
    flex-shrink: 0;
    flex-direction: row-reverse;
    align-items: center;
    padding: 60px 95px;

    .review-image {
      width: 445px;
      height: 445px;
    }

    .left {
      margin-right: 30px;
    }
    .review-title {
      font-size: 24px;
      line-height: 28px;
      /* identical to box height */
      margin: 15px 0 10px;

      letter-spacing: 0.380208px;
    }

    .review-body {
      font-size: 18px;
      line-height: 160%;
      margin-bottom: 50px;
    }

    .review-footer {
      font-size: 20px;
      line-height: 23px;
    }
  }
`

export const MobileNavArrows = styled.button`
  padding: 0;

  .icon-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
