import styled from 'styled-components'

export const StyledWrapper = styled.div`
  margin-top: 60px;
  max-width: 100%;
  overflow: hidden;
  padding-bottom: 100px;

  .testimonial_title {
    font-size: 24px;
    font-weight: var(--brand-font-weight-400);
    text-align: center;
    font-family: var(--brand-font-family-title);
    margin-bottom: 40px;
  }

  .image-gallery-bullets {
    position: absolute;
    bottom: -48px;
  }
  .image-gallery-bullets-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .image-gallery-bullet {
    border: none;
    background-color: black;
    width: 6px;
    height: 6px;
    box-shadow: none;
    margin-left: 9px;
  }
  .image-gallery-bullet:first-child {
    margin-left: 0;
  }
  .image-gallery-bullet.active {
    background-color: var(--brand-primary-green-color);
    border: none;
    width: 12px;
    height: 12px;
  }

  .img-gallery {
    width: 70%;
    margin: auto;

    .image-gallery-slides {
      overflow: visible;
      max-width: 100vw;
      display: flex;
    }
    .image-gallery-slide {
      padding: 0
        min(40px, calc(12px + (40 - 12) * ((100vw - 360px) / (1440 - 360))));
      display: flex;
      height: 100%;
    }

    .image-gallery-slide.center {
      padding: 0;
    }

    .image-gallery-slide.center .video_container {
      height: min(
        572px,
        calc(180px + (572 - 180) * ((100vw - 360px) / (1440 - 360)))
      );
    }

    .image-gallery-slide.center .stopper {
      display: none;
    }
    .stopper {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  @media (min-width: 768px) {
    margin-top: 80px;

    .testimonial_title {
      font-size: 48px;
      margin-bottom: 60px;
    }

    .image-gallery-bullet {
      width: 10px;
      height: 10px;
    }
    .image-gallery-bullet.active {
      width: 20px;
      height: 20px;
    }
    .img-gallery {
      width: 100%;
      max-width: 1200px;
      margin: auto;

      .image-gallery-slides {
        max-width: 900px;
        margin: auto;
        overflow: hidden;
      }
    }
  }
`
