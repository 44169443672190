import React from 'react'

function Left({height = 22, width = 14}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 14 22"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
        d="M11.5 20l-9-9 9-9"
      ></path>
    </svg>
  )
}

export default Left
