import styled from 'styled-components'

export const StyledHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 15px 24px 15px;

  @media screen and (min-width: 768px) {
    margin: 0;
  }
`
export const StyledTopHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap:8px;
  justify-content: flex-start;
  margin-bottom: 16px;

  .heading-wrapper {
    padding: 0;
  }

  .heading-text {
    font-weight: var(--brand-font-weight-bold);
    font-size: 20px;
    line-height: 30px;
  }

@media screen and (min-width: 768px) {
  flex-direction:column;
  justify-content: space-between;
  gap:16px;

  .heading-wrapper {
    margin-left:0;
  }
 
  .heading-text {
    font-size: 26px;
    line-height: 39px;
  }
`
export const StyledBottomHeaderContainer = styled.div`
  .subheading-text {
    font-size: 14px;
    line-height: 21px;
    font-weight: var(--brand-font-weight-normal);
    color: var(--brand-light-secondary-text-color);
  }

  @media screen and (min-width: 768px) {
    .subheading-text {
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      margin-bottom: 32px;
      font-weight: var(--brand-font-weight-normal);
    }
  }
`
