import {StyledWrapper} from './styles'
import QualityStory from '../QualityStory/QualityStory'
import isEmpty from 'lodash/isEmpty'
import {withCustomErrorBoundary} from '../../../../utils/withCustomErrorBoundary'

const OurStory = (props) => {
  const {text = ''} = props.state || {}

  if (isEmpty(props.state)) {
    return null
  }

  return (
    <StyledWrapper>
      <QualityStory {...props} className="our_story">
        <div
          className="story_text"
          dangerouslySetInnerHTML={{__html: text}}
        ></div>
      </QualityStory>
    </StyledWrapper>
  )
}

export default withCustomErrorBoundary(OurStory, 'OurStory')
