import isEmpty from 'lodash/isEmpty'

import {withCustomErrorBoundary} from '../../../../utils/withCustomErrorBoundary'
import OptimizedImage from '../../../common/OptimizedImage'
import {StyledWrapper} from './styles'

const WhyLittleJoys = (props) => {
  const {mainImage = '', dataCards = []} = props.state || {}

  if (isEmpty(dataCards)) {
    return null
  }
  return (
    <StyledWrapper>
      <img
        className="why_lj_main_image"
        src={mainImage}
        alt="Why Little joys"
        loading="eager"
        width={360}
        height={129}
      />

      <div className="why_lj_data_cards">
        {dataCards.map((data, index) => {
          const {icon = '', title = '', text = '', color = ''} = data || {}
          return (
            <div key={`why-lj-${index}`} className="why_lj_data_card">
              <OptimizedImage
                source={icon}
                aspectWidth="100"
                aspectHeight="100"
                desktopViewWidth="SMALL"
                mobileViewWidth="SMALL"
                altText={title}
                loading="lazy"
              />
              <h1 style={{color}}>{title}</h1>
              <p>{text}</p>
            </div>
          )
        })}
      </div>
    </StyledWrapper>
  )
}

export default withCustomErrorBoundary(WhyLittleJoys, 'WhyLittleJoys')
