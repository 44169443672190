import React, {useState, useCallback} from 'react'
import noop from 'lodash/noop'

import Modal from '../Modal'
import RadioInput from '../RadioInput'
import OptimizedImage from '../OptimizedImage'
import {FILTER_CONSTANTS} from './constants'
import {
  StyledFilterModalFooterButton,
  StyledFilterModalHeader,
  StyledFilterModalContainer,
  StyledModalContentWrap,
  StyledFilterModalFooter,
  StyledImageConcernVariant,
  StyledImageConcernVariantContainer,
  StyledOption,
} from './styles'
import {IFilterSelectorModalProps, IOption} from './FilterDropDownBox.types'

const FilterSelectorModal = (props: IFilterSelectorModalProps) => {
  const {
    filter,
    options = [],
    filtersCta = [],
    isModalOpen = true,
    onClose = noop,
    applyFilter = noop,
    removeFilter = noop,
    isFilterApplied = false,
  } = props || {}
  const {label: topFilterLabel, type = ''} = filter || {}

  const [selectedOption, setSelectedOption] = useState<IOption | {}>({})
  const handleApplyFilter = useCallback(() => {
    applyFilter(filter, selectedOption)
    onClose()
  }, [applyFilter, filter, selectedOption, onClose])

  const handleClearFilter = useCallback(() => {
    setSelectedOption({})
    removeFilter(filter)
  }, [removeFilter, filter])

  return (
    <StyledFilterModalContainer>
      <Modal
        showModal={isModalOpen}
        onClose={onClose}
        addtionalButtonClass="filter-close-button"
        additionalContentClass="filter-modal"
        cancelCloseIcon={true}
        isPadding={false}
      >
        <StyledModalContentWrap data-testid="filter-modal-options">
          <StyledFilterModalHeader>{topFilterLabel}</StyledFilterModalHeader>

          {type === FILTER_CONSTANTS.TEXT_TYPE &&
            options?.map((filterOption) => {
              const {label = '', key = ''} = filterOption || {}
              return (
                <StyledOption key={key} data-testid={key}>
                  <RadioInput
                    key={key}
                    id={key}
                    name="filter-options"
                    labelPosition="end"
                    justifyContent="space-between"
                    onChange={() => setSelectedOption(filterOption)}
                    value={label}
                    label={label}
                    disabled={isFilterApplied && key !== selectedOption.key}
                    checked={key === selectedOption?.key}
                  />
                </StyledOption>
              )
            })}
          {type === FILTER_CONSTANTS.IMAGE_TYPE && (
            <StyledImageConcernVariantContainer>
              {options.map((filterOption) => {
                const {label = '', key = '', imageUrl = ''} = filterOption || {}
                return (
                  <StyledImageConcernVariant
                    isClicked={key === selectedOption.key}
                    onClick={() => {
                      setSelectedOption(filterOption)
                    }}
                    disabled={isFilterApplied && key !== selectedOption.key}
                    key={key}
                    data-testid={key}
                  >
                    <div className="each-concern-option-image-container">
                      <OptimizedImage
                        source={imageUrl}
                        aspectWidth="56"
                        aspectHeight="56"
                        desktopViewWidth="SMALL"
                        mobileViewWidth="SMALL"
                        altText={label}
                        loading="lazy"
                        customClassName="concern-image"
                      />
                    </div>
                    <div className="each-concern-option-label">
                      <label>{label}</label>
                    </div>
                  </StyledImageConcernVariant>
                )
              })}
            </StyledImageConcernVariantContainer>
          )}
        </StyledModalContentWrap>

        <StyledFilterModalFooter isFilterApplied={isFilterApplied}>
          {filtersCta.map((cta) => {
            const {type = '', ctaText = ''} = cta || {}
            return (
              <StyledFilterModalFooterButton
                key={type}
                data-testid={type}
                disabled={
                  (type === FILTER_CONSTANTS.APPLY_OPTION && isFilterApplied) ||
                  (type === FILTER_CONSTANTS.APPLY_OPTION &&
                    !selectedOption?.key &&
                    !isFilterApplied) ||
                  (type === FILTER_CONSTANTS.CLEAR_OPTION && !isFilterApplied)
                }
                onClick={
                  type === FILTER_CONSTANTS.APPLY_OPTION
                    ? handleApplyFilter
                    : handleClearFilter
                }
                ctaType={type}
              >
                {ctaText}
              </StyledFilterModalFooterButton>
            )
          })}
        </StyledFilterModalFooter>
      </Modal>
    </StyledFilterModalContainer>
  )
}

export default FilterSelectorModal
