import styled from 'styled-components'

export const StyledWrapper = styled.div`
  width: 100%;
  margin-top: 45px;

  .why_lj_main_image {
    width: 100%;
    height: auto;
  }

  .why_lj_data_card {
    padding: 20px;
  }
  .why_lj_data_card img {
    width: 50px;
    object-fit: contain;
  }

  .why_lj_data_card h1 {
    margin: 0;
    padding: 0;
    margin-top: 15px;
    font-size: 20px;
    font-weight: var(--brand-font-weight-400);
    font-family: var(--brand-font-family-title);
  }
  .why_lj_data_card p {
    margin: 0;
    padding: 0;
    margin-top: 4px;
    font-size: 14px;
    font-weight: var(--brand-font-weight-400);
    font-family: var(--brand-font-family-text);
  }

  @media (min-width: 768px) {
    max-width: 1120px;
    margin: auto;
    margin-top: 80px;

    .why_lj_data_cards {
      display: flex;
      gap: 65px;
    }
    .why_lj_data_card {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
    }
    .why_lj_data_card img {
      width: 100px;
    }
    .why_lj_data_card p,
    .why_lj_data_card h1 {
      text-align: center;
    }

    .why_lj_data_card h1 {
      font-size: 32px;
      margin-top: 40px;
    }
    .why_lj_data_card p {
      font-size: 20px;
      margin-top: 8px;
    }
  }
`
