import * as React from 'react'

const Tick = (props) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.06 5.74A1.5 1.5 0 1 0 .94 7.86l2.12-2.12ZM5.2 10l-1.06 1.06a1.5 1.5 0 0 0 2.203-.088L5.2 10Zm7.943-7.029a1.5 1.5 0 0 0-2.286-1.942l2.286 1.942ZM.939 7.861l3.2 3.2 2.122-2.122-3.2-3.2L.939 7.861Zm5.404 3.11 6.8-8-2.286-1.942-6.8 8 2.286 1.943Z"
      fill="#42B682"
    />
  </svg>
)

export default Tick
