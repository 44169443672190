import React, {memo, useCallback, useEffect, useState} from 'react'
import VideoEmbed, {PlayIcon} from '../../../../../common/VideoEmbed'
import {StyledWrapper} from './styles'

const RenderItem = (props) => {
  const {video, isActive, thumbnail} = props
  const [isPlaying, setIsPlaying] = useState(false)

  useEffect(() => {
    if (isPlaying && !isActive) {
      setIsPlaying(false)
    }
  }, [isActive, isPlaying])

  const handlePause = useCallback(() => {
    setIsPlaying(false)
  }, [])
  const handlePlay = useCallback(() => {
    setIsPlaying(true)
  }, [])

  return (
    <StyledWrapper>
      <div className="video_container">
        <VideoEmbed
          videoLink={video}
          thumbnail={thumbnail}
          playIcon={<PlayIcon />}
          playing={isPlaying}
          onPlay={handlePlay}
          onPause={handlePause}
          controls
        />
      </div>
      <div className="stopper"></div>
    </StyledWrapper>
  )
}

export default memo(RenderItem)
