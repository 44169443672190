import styled from 'styled-components'

export const StyledWrapper = styled.div`
  background-color: var(--brand-primary-red-bg1-color);

  .quality_story_title {
    color: var(--brand-primary-red-color);
  }

  .story_text {
    font-family: var(--brand-font-family-text);
    font-weight: var(--brand-font-weight-400);
    font-size: 14px;
    margin-top: 8px;
  }

  @media (min-width: 768px) {
    .story_text {
      margin-top: 20px;
      font-size: 20px;
    }
  }
`
