import styled from 'styled-components'

export const StyledInFocusBanner = styled.div`
  margin: 30px 0px 0;
  padding: 0px 20px;
  display: flex;
  width: 100%;
  justify-content: center;
  .wrapper {
    background-color: ${({bgColor}) => bgColor};
    border-radius: 20px;
    padding: 20px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 745px;
  }
  .wrapper-with-image {
    display: flex;
  }
  .image {
    width: 100px;
    height: 100px;
    margin-right: 20px;
  }
  .title,
  .info {
    text-align: center;
    margin: 0;
    width: 100%;
  }

  .title {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    font-family: var(--brand-font-family-title);
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #588ece;
    margin-bottom: 10px;
  }

  .info {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #333333;
    margin-bottom: 26px;
  }

  .action {
    border: none;
    background-color: #000;
    border-radius: 12px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    position: relative;
    color: #ffffff;
    padding: 12px 54px;
    overflow: hidden;
    -webkit-highlight: none;
    -webkit-tap-highlight-color: transparent;
  }
  .with-image {
    text-align: left;
  }
  .button-with-image {
    width: 100%;
  }
  @media (min-width: 768px) {
    margin: 80px auto 0;
    .wrapper {
      border-radius: 30px;
      padding: 40px 60px;
    }
    .image {
      width: 150px;
      height: 150px;
      margin-right: 20px;
      margin-bottom: 20px;
    }
    .action {
      border-radius: 20px;
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      padding: 18px 83px;
    }
    .title {
      width: 625px;
      font-size: 36px;
      line-height: 120%;
      margin-bottom: 14px;
    }

    .info {
      width: 515px;
      font-size: 20px;
      line-height: 150%;
      margin-bottom: 20px;
    }
    .with-image {
      width: auto;
    }
  }
`
