import React from 'react'
import {
  FirstFoldCarousel,
  WhyLittleJoys,
  VideoSection,
  ProductFinder,
  Expert,
  OurStory,
  QualityCheck,
  Gallery,
  Testimonials,
} from './index'

const HomePage = (props) => {
  const {
    firstFold = {},
    whyLittleJoys = {},
    videoSection = {},
    productFinder = {},
    expert = {},
    ourStory = {},
    qualityCheck = {},
    gallery = {},
    testimonials = {},
  } = props.state || {}

  const {onAddToCard = () => {}} = props.actions || {}

  const formattedFirstFold = {
    state: {
      ...firstFold,
    },
  }
  const formattedWhyLittleJoys = {
    state: {
      ...whyLittleJoys,
    },
  }
  const formattedVideoSection = {
    state: {
      ...videoSection,
    },
  }
  const formattedProductFinder = {
    state: {
      ...productFinder,
    },
    actions: {
      onAddToCard,
    },
  }
  const formattedExpert = {
    state: {
      ...expert,
    },
  }
  const formattedOurStory = {
    state: {
      ...ourStory,
    },
  }
  const formattedQualityCheck = {
    state: {
      ...qualityCheck,
    },
  }
  const formattedGallery = {
    state: {
      ...gallery,
    },
  }
  const formattedTestimonial = {
    state: {
      ...testimonials,
    },
  }

  return (
    <div>
      <FirstFoldCarousel {...formattedFirstFold} />
      <WhyLittleJoys {...formattedWhyLittleJoys} />
      <VideoSection {...formattedVideoSection} />
      <ProductFinder {...formattedProductFinder} />
      <Expert {...formattedExpert} />
      <OurStory {...formattedOurStory} />
      <QualityCheck {...formattedQualityCheck} />
      <Testimonials {...formattedTestimonial} />
      <Gallery {...formattedGallery} />
    </div>
  )
}

export default HomePage
