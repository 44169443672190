import styled from 'styled-components'

export const StyledWrapper = styled.div`
  width: 100%;
  /* margin-top: 60px; */
  display: flex;
  flex-direction: column;
  align-items: center;

  .gallery_top_wrapper {
    width: 100%;
    background-color: var(--brand-primary-green-bg1-color);
    position: relative;
    padding-bottom: 350px;
  }

  .gallery_carousel_wrap {
    position: relative;
  }

  .gallery_main_image {
    width: 100%;
    margin-top: 60px;
  }
  .custom_carousel_wrapper_style {
    position: absolute;
    max-width: 100%;
    top: 0;
  }

  .gallery_list_item {
    width: 300px;
    height: 420px;
    margin-left: 5px;
    border-radius: 30px;
    overflow: hidden;
  }
  .gallery_list_item:first-child {
    margin-left: 12px;
  }
  .gallery_list_item:last-child {
    margin-right: 12px;
  }

  .gallery_list_item a {
    width: 100%;
    height: 100%;
    display: block;
  }
  .gallery_list_item_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .gallery_btn {
    margin-top: 120px;
  }

  @media (min-width: 768px) {
    margin-top: 0px;

    .gallery_container {
      max-width: 90%;
      margin: auto;
    }

    .gallery_main_image {
      margin-top: 120px;
    }

    .gallery_list_item {
      width: 360px;
      height: 500px;
      margin-left: 16px;
    }
    .gallery_list_item:first-child {
      margin-left: 0;
    }
    .gallery_list_item:last-child {
      margin-right: 0;
    }

    .gallery_top_wrapper {
      padding-bottom: 400px;
    }

    .gallery_btn {
      margin-top: 180px;
    }
  }

  @media (min-width: 1125px) {
    .gallery_container {
      max-width: 1120px;
    }
  }
`
