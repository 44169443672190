import noop from 'lodash/noop'
import isEmpty from 'lodash/isEmpty'
import React, {useMemo} from 'react'
import ImageGallery from 'react-image-gallery'
import {useCallback} from 'react'
import {useState} from 'react'
import CustomerReview from './CustomerReview'
import {MobileNavArrows, StyledReviews} from './CustomerReviews.styles'
import Right from '../../../common/Icons/CarouselArrows/Right'
import Left from '../../../common/Icons/CarouselArrows/Left'
import Responsive from '../../../HOC/Responsive'
import {withCustomErrorBoundary} from '../../../../utils/withCustomErrorBoundary'

function CustomerReviews({state = {}, actions = {}, isMobile}) {
  const {title = '', reviews = []} = state
  const {onSlideChange = noop} = actions
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)
  const mappedReviews = useMemo(() => {
    if (!Array.isArray(reviews)) return []
    return reviews.map((review) => {
      return {
        ...review,
        renderItem: (item) => {
          return (
            <div>
              <CustomerReview {...item} />
            </div>
          )
        },
      }
    })
  }, [reviews])

  const handleSlide = useCallback((currentSlide) => {
    setActiveSlideIndex(currentSlide)
  }, [])

  const [carouselNavWidth, carouselNavHeight] = useMemo(() => {
    if (isMobile) {
      return [5.5, 11]
    }

    return [9, 18]
  }, [isMobile])

  if (isEmpty(reviews)) {
    return null
  }

  return (
    <StyledReviews>
      <div className="container">
        <ImageGallery
          items={mappedReviews}
          lazyLoad
          showBullets={false}
          useTranslate3D
          autoPlay={false}
          showPlayButton={false}
          startIndex={activeSlideIndex}
          showFullscreenButton={false}
          showThumbnails={false}
          showNav={true}
          additionalClass="custom-review-gallery"
          onSlide={handleSlide}
          renderLeftNav={(onClick, disabled) => (
            <MobileNavArrows
              type="button"
              onClick={onClick}
              disabled={disabled}
              className="carousel-mobile-nav left"
              color="white"
            >
              <span className="icon-wrap">
                <Left height={carouselNavHeight} width={carouselNavWidth} />
              </span>
            </MobileNavArrows>
          )}
          renderRightNav={(onClick, disabled) => (
            <MobileNavArrows
              type="button"
              onClick={onClick}
              disabled={disabled}
              className="carousel-mobile-nav right"
              color="white"
            >
              <span className="icon-wrap">
                <Right height={carouselNavHeight} width={carouselNavWidth} />
              </span>
            </MobileNavArrows>
          )}
        ></ImageGallery>
        <div className="carousel_dots">
          {mappedReviews.map((review, index) => {
            const isActive = index === activeSlideIndex
            return (
              <div
                key="index"
                className="carousel_dot"
                data-isactive={isActive}
              ></div>
            )
          })}
        </div>
      </div>
    </StyledReviews>
  )
}

export default Responsive(
  withCustomErrorBoundary(CustomerReviews, 'CustomerReviews')
)
