import React from 'react'
import styled from 'styled-components'

const StyledSvg = styled.svg`
  > path {
    stroke: var(--brand-primary-color);
  }
`
function Right({height = 22, width = 14}) {
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 14 22"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
        d="M2.5 20l9-9-9-9"
      ></path>
    </StyledSvg>
  )
}

export default Right
