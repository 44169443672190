import React, {useMemo, useEffect, useState, useCallback} from 'react'
import noop from 'lodash/noop'

import HeaderContainer from './HeaderContainer'
import FilterDropDownBox from '../../../common/FilterDropDownBox'
import ActionButtonContainer from './ActionButtonContainer'
import {StyledConcernHelpContainer} from './styles'
import {
  IFilter,
  IOption,
} from '../../../common/FilterDropDownBox/FilterDropDownBox.types'
import {
  IConcernHelpFilters,
  IAppliedConcernFilters,
} from './ConcernHelpFilters.types'
import Responsive from '../../../HOC/Responsive'

function ConcernHelpFilters(props: IConcernHelpFilters) {
  const {isMobile = false} = props
  const {
    sectionName = '',
    title = '',
    subTitle = '',
    mobileIcon = '',
    desktopIcon = '',
    iconAltText = '',
    filters = [],
    options = [],
    filtersCta = [],
    ctaPermutations = [],
  } = props.state || {}

  const {
    onConcernFilterOptionSelected = noop,
    getMappedCTAData,
    handleCta = noop,
  } = props.actions || {}

  const [appliedConcernFilters, setAppliedConcernFilters] = useState<
    IAppliedConcernFilters | {}
  >({})

  const initializeFilters = useCallback((filters: IFilter[]) => {
    filters.forEach((filter: IFilter) =>
      setAppliedConcernFilters((appliedConcernFilters) => ({
        ...appliedConcernFilters,
        [filter.key]: {},
      }))
    )
  }, [])

  const onFilterOptionSelect = useCallback(
    (filter: IFilter, selectedOption: IOption) => {
      setAppliedConcernFilters((appliedConcernFilters) => ({
        ...appliedConcernFilters,
        [filter.key]: selectedOption,
      }))
      onConcernFilterOptionSelected(
        filter.label,
        selectedOption.label,
        sectionName
      )
    },
    [onConcernFilterOptionSelected, sectionName]
  )

  const onClearFilter = useCallback((filter: IFilter) => {
    setAppliedConcernFilters((appliedConcernFilters) => ({
      ...appliedConcernFilters,
      [filter.key]: {},
    }))
  }, [])

  const headerContainerArgs = useMemo(() => {
    return {
      title,
      subTitle,
      mobileIcon,
      desktopIcon,
      iconAltText,
      isMobile,
    }
  }, [title, subTitle, mobileIcon, desktopIcon, iconAltText, isMobile])

  const filterContainerArgs = useMemo(() => {
    return {
      state: {filters, options, filtersCta, appliedConcernFilters},
      actions: {
        onFilterOptionSelect,
        onClearFilter,
      },
    }
  }, [
    filters,
    options,
    filtersCta,
    appliedConcernFilters,
    onFilterOptionSelect,
    onClearFilter,
  ])

  const actionButtonContainerArgs = useMemo(() => {
    const mappedCtaData = getMappedCTAData(
      appliedConcernFilters,
      ctaPermutations
    )
    return {
      state: mappedCtaData || {},
      actions: {handleCta},
    }
  }, [getMappedCTAData, appliedConcernFilters, ctaPermutations, handleCta])

  useEffect(() => {
    if (filters && filters.length) {
      initializeFilters(filters)
    }
  }, [initializeFilters, filters])

  return (
    <StyledConcernHelpContainer>
      <HeaderContainer {...headerContainerArgs} />
      <FilterDropDownBox {...filterContainerArgs} />
      <ActionButtonContainer {...actionButtonContainerArgs} />
    </StyledConcernHelpContainer>
  )
}

export default Responsive(ConcernHelpFilters)
