import {useCallback, useState} from 'react'
import {withCustomErrorBoundary} from '../../../../utils/withCustomErrorBoundary'
import HeadingComponent from '../../../common/HeadingComponent'
import VideoEmbed, {PlayIcon} from '../../../common/VideoEmbed'
import {StyledWrapper} from './styles'

const VideoSection = (props) => {
  const {
    headingLevel = 2,
    title = '',
    text = '',
    video = '',
    thumbnail,
  } = props.state || {}
  const [isPlaying, setIsPlaying] = useState(false)
  const handlePlay = useCallback(() => {
    setIsPlaying(true)
  }, [])

  const handlePause = useCallback(() => {
    setIsPlaying(false)
  }, [])

  if (!video) {
    return null
  }

  return (
    <StyledWrapper>
      <HeadingComponent
        headingLevel={headingLevel}
        customClassName="video_title"
        label={title}
      />
      <div className="video_text">{text}</div>
      <div className="video_container">
        <VideoEmbed
          videoLink={video}
          playIcon={<PlayIcon />}
          playing={isPlaying}
          onPlay={handlePlay}
          onPause={handlePause}
          thumbnail={thumbnail}
          controls
        />
      </div>
    </StyledWrapper>
  )
}

export default withCustomErrorBoundary(VideoSection, 'VideoSection')
