import React, {useCallback} from 'react'
import noop from 'lodash/noop'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronRight} from '@fortawesome/free-solid-svg-icons'
import HeadingComponent from '../../../../common/HeadingComponent'
import {ActionButtonWrapper, StyledCTA, StyledCTAWrapper} from './styles'
import {IActionButtonContainerProps, ICta} from './ActionButtonContainer.types'

function ActionButtonContainer(props: IActionButtonContainerProps) {
  const {description = '', ctaData = []} = props.state || {}
  const {handleCta = noop} = props.actions || {}

  const handleClick = useCallback(
    (cta: ICta) => {
      handleCta(cta)
    },
    [handleCta]
  )

  if (!ctaData || !ctaData.length) return null
  return (
    <ActionButtonWrapper>
      <HeadingComponent customClassName="subheading-text" label={description} />
      <StyledCTAWrapper>
        {ctaData.map((cta, index) => {
          const {
            label = '',
            isPrimary = false,
            showChevron = false,
            showTag = false,
            tagColor = '',
            tagText = '',
          } = cta || {}
          return (
            <StyledCTA
              noOfButtons={ctaData.length}
              isPrimary={isPrimary}
              tagColor={tagColor}
              key={label}
              data-testid={`cta-button-${index}`}
              onClick={() => {
                handleClick(cta)
              }}
            >
              <span>{label}</span>
              {showTag && <span className="tag-text">{tagText}</span>}
              {showChevron && (
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="font-awesome-icon"
                  data-testid={`chevron-right-${index}`}
                />
              )}
            </StyledCTA>
          )
        })}
      </StyledCTAWrapper>
    </ActionButtonWrapper>
  )
}

export default ActionButtonContainer
