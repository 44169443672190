import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import React, {useCallback} from 'react'

import {withCustomErrorBoundary} from '../../../../utils/withCustomErrorBoundary'
import HeadingComponent from '../../../common/HeadingComponent'
import OptimizedImage from '../../../common/OptimizedImage'
import {StyledInFocusBanner} from './InFocusBanner.styles'

function InFocusBanner({state, actions}) {
  const {
    headingLevel = 2,
    backgroundColor,
    cta,
    info,
    title,
    image = '',
    isLoginRequired = false,
  } = state || {}
  const {label} = cta || {}
  const {inFocusBannerClick = noop} = actions || {}

  const handleClick = useCallback(
    (e) => {
      e.preventDefault()
      inFocusBannerClick(isLoginRequired, cta)
    },
    [cta, inFocusBannerClick, isLoginRequired]
  )

  if (isEmpty(state)) {
    return null
  }
  return (
    <StyledInFocusBanner
      as="a"
      bgColor={backgroundColor}
      onClick={handleClick}
      className="in-focus-banner-container"
    >
      <div className="wrapper">
        <div className="wrapper-with-image">
          {image && (
            <OptimizedImage
              source={image}
              aspectWidth="100"
              aspectHeight="100"
              desktopViewWidth="THIRD"
              mobileViewWidth="THIRD"
              altText="in-focus-icon"
              loading="lazy"
              customClassName="image"
            />
          )}
          <div>
            <HeadingComponent
              headingLevel={headingLevel}
              customClassName={image ? 'title with-image' : 'title'}
              label={title}
              isDangerouslySet={true}
            />
            <p
              className={image ? 'info with-image' : 'info'}
              dangerouslySetInnerHTML={{__html: info}}
            />
          </div>
        </div>
        <button
          type="button"
          className={
            image
              ? 'action brand-neutral-btn button-with-image'
              : 'action brand-neutral-btn'
          }
        >
          {label}
        </button>
      </div>
    </StyledInFocusBanner>
  )
}

export default withCustomErrorBoundary(InFocusBanner, 'InFocusBanner')
