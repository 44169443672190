import React from 'react'

import HeadingComponent from '../../../../common/HeadingComponent'
import OptimizedImage from '../../../../common/OptimizedImage'
import {
  StyledBottomHeaderContainer,
  StyledHeaderContainer,
  StyledTopHeaderContainer,
} from './styles'

function HeaderContainer({
  title = '',
  subTitle = '',
  mobileIcon = '',
  desktopIcon = '',
  iconAltText = 'Help icon',
  isMobile = false,
}) {
  return (
    <StyledHeaderContainer data-testid="header-container">
      <StyledTopHeaderContainer>
        <div>
          <OptimizedImage
            source={isMobile ? mobileIcon : desktopIcon}
            aspectWidth="72"
            aspectHeight="72"
            desktopViewWidth="SMALL"
            mobileViewWidth="SMALL"
            altText={iconAltText}
            loading="lazy"
          />
        </div>
        <div className="heading-wrapper">
          <HeadingComponent label={title} customClassName="heading-text" />
        </div>
      </StyledTopHeaderContainer>
      <StyledBottomHeaderContainer>
        <HeadingComponent customClassName="subheading-text" label={subTitle} />
      </StyledBottomHeaderContainer>
    </StyledHeaderContainer>
  )
}

export default HeaderContainer
