import isEmpty from 'lodash/isEmpty'
import ImageGallery from 'react-image-gallery'
import {StyledWrapper} from './styles'
import {RenderItem} from './index'
import {LeftNav, RightNav} from '../FirstFoldCarousel'
import Responsive from '../../../HOC/Responsive'
import {useCallback, useMemo, useState} from 'react'
import HeadingComponent from '../../../common/HeadingComponent'
import {withCustomErrorBoundary} from '../../../../utils/withCustomErrorBoundary'

const Testimonials = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const {state = {}, isMobile} = props
  const {
    headingLevel = 2,
    title = 'Happy Kids, Honest Reviews',
    videos = [],
  } = state

  const handleIndexChange = useCallback((index) => {
    setCurrentIndex(index)
  }, [])

  const mappedVideos = useMemo(() => {
    return videos
      ? videos.map((video, index) => {
          return {
            ...video,
            renderItem: (videoItem) => {
              return (
                <RenderItem {...videoItem} isActive={index === currentIndex} />
              )
            },
          }
        })
      : []
  }, [currentIndex, videos])

  if (isEmpty(videos)) return null

  const isNavShow = !isMobile

  return (
    <StyledWrapper>
      <HeadingComponent
        headingLevel={headingLevel}
        customClassName="testimonial_title"
        label={title}
      />
      <ImageGallery
        additionalClass="img-gallery"
        showNav={isNavShow}
        showThumbnails={false}
        showFullscreenButton={false}
        showPlayButton={false}
        infinite={false}
        showBullets={true}
        items={mappedVideos}
        onSlide={handleIndexChange}
        renderLeftNav={(onClick, disabled) => {
          return <LeftNav disabled={disabled} onclick={onClick} />
        }}
        renderRightNav={(onClick, disabled) => {
          return <RightNav disabled={disabled} onclick={onClick} />
        }}
        startIndex={currentIndex}
        // renderItem={(item) => <RenderItem {...item} />}
      />
    </StyledWrapper>
  )
}

export default Responsive(withCustomErrorBoundary(Testimonials, 'Testimonials'))
