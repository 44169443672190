import ArrowLeft from '../../../../assets/icons/ArrowLeftBlack.svg'
import ArrowRight from '../../../../assets/icons/ArrowRightBlack.svg'
import {withCustomErrorBoundary} from '../../../../utils/withCustomErrorBoundary'
import {LargeRoundedBtn} from '../../../common/Button'
import CustomCarousel from '../../../common/CustomCarousel'
import HeadingComponent from '../../../common/HeadingComponent'
import OptimizedImage from '../../../common/OptimizedImage'
import {StyledWrapper} from './styles'

const Expert = (props) => {
  const {
    headingLevel = 2,
    title = '',
    text = '',
    buttonText,
    link = '',
    experts = [],
  } = props.state || {}
  return (
    <StyledWrapper>
      <HeadingComponent
        headingLevel={headingLevel}
        customClassName="expert_title"
        label={title}
      />
      <div className="expert_text">{text}</div>

      <CustomCarousel
        iconLeft={ArrowLeft}
        iconRight={ArrowRight}
        scrollWidth={390}
        scrollDuration={100}
      >
        {experts.map((expert, index) => {
          const {
            image = '',
            title = '',
            text = '',
            color = '#42B682',
          } = expert || {}
          return (
            <div
              className="expert_list_item"
              style={{background: color}}
              key={`expert-${index}`}
            >
              <OptimizedImage
                source={image}
                aspectWidth="130"
                aspectHeight="130"
                desktopViewWidth="THIRD"
                mobileViewWidth="THIRD"
                altText={title}
                loading="lazy"
                customClassName="expert_list_item_image"
              />
              <div className="expert_list_item_text_wrapper">
                <div className="expert_list_item_title">{title}</div>
                <div className="expert_list_item_text">{text}</div>
              </div>
            </div>
          )
        })}
      </CustomCarousel>

      {buttonText && (
        <LargeRoundedBtn text={buttonText} link={link} className="expert_btn" />
      )}
    </StyledWrapper>
  )
}

export default withCustomErrorBoundary(Expert, 'Expert')
