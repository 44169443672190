import {LargeRoundedBtn} from '../../../common/Button'
import OptimizedImage from '../../../common/OptimizedImage'
import {StyledWrapper} from './styles'

const QualityStory = (props) => {
  const {
    title = 'quality story',
    image = '',
    buttonText,
    link = '',
    className = '',
  } = props.state || {}
  return (
    <StyledWrapper className={className}>
      <div className="quality_story_container">
        <div className="quality_story_image">
          <div className="quality_story_image_wrapper">
            <OptimizedImage
              source={image}
              aspectWidth="100"
              aspectHeight="100"
              desktopViewWidth="THIRD"
              mobileViewWidth="THIRD"
              altText={title}
              loading="lazy"
            />
          </div>
        </div>
        <div className="quality_story_right">
          <div className="quality_story_title">{title}</div>
          {props.children}
          {buttonText && (
            <LargeRoundedBtn
              className="quality_story_button"
              text={buttonText}
              link={link}
            />
          )}
        </div>
      </div>
    </StyledWrapper>
  )
}

export default QualityStory
