import styled from 'styled-components'

export const StyledWrapper = styled.div`
  width: 100%;
  padding: 120px 20px 60px 20px;
  margin-top: 310px;
  box-sizing: border-box;
  position: relative;

  .quality_story_container {
    width: 100%;
    max-width: 1120px;
    margin: auto;
  }

  .quality_story_title {
    font-family: var(--brand-font-family-title);
    font-size: 28px;
    font-weight: var(--brand-font-weight-400);
  }

  .quality_story_text {
    font-family: var(--brand-font-family-text);
    font-weight: var(--brand-font-weight-400);
    font-size: 14px;
    margin-top: 8px;
  }

  .quality_story_button {
    margin-top: 20px;
  }

  .quality_story_image {
    position: absolute;
    top: -300px;
    left: 0;
    width: 100%;
    overflow: hidden;
    height: 400px;
    box-sizing: border-box;
    padding: 0 20px;
  }

  .quality_story_image_wrapper {
    width: 100%;
    height: 100%;
    background-color: var(--brand-primary-white-color);
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .quality_story_image_wrapper img {
    height: 100%;
    width: 100%;
  }

  @media (min-width: 768px) {
    margin-top: 80px;
    padding: 100px 20px 100px 20px;

    .quality_story_container {
      display: flex;
      align-items: center;
    }

    .quality_story_image {
      position: relative;
      top: 0;
      left: 0;
      max-width: 336px;
      height: 336px;
      padding: 0;
      margin-right: 40px;
    }

    .quality_story_right {
      flex: 1;
    }

    .quality_story_title {
      font-size: 60px;
    }

    .quality_story_text {
      margin-top: 20px;
      font-size: 20px;
    }
    .quality_story_button {
      margin-top: 40px;
    }
  }

  @media (min-width: 900px) {
    .quality_story_image {
      max-width: 400px;
      height: 400px;
      margin-right: 60px;
    }
  }
`
