import styled from 'styled-components'

export const StyledConcernHelpContainer = styled.div`
  width: 100%;
  margin: auto;
  margin-bottom: 56px;
  @media screen and (min-width: 768px) {
    width: 752px;
  }
`
