import styled from 'styled-components'

export const StyledWrapper = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;

  .expert_title,
  .expert_text {
    margin: 0 20px;
  }

  .expert_title {
    font-size: 28px;
    font-weight: var(--brand-font-weight-400);
    font-family: var(--brand-font-family-title);
    color: var(--brand-primary-green-color);
  }
  .expert_text {
    font-size: 14px;
    font-weight: var(--brand-font-weight-normal);
    font-family: var(--brand-font-family-text);
    margin-top: 4px;
    line-height: 140%;
  }

  .expert_list {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    overflow: scroll;
  }
  .expert_list_item {
    width: 300px;
    min-width: 300px;
    border-radius: 20px;
    overflow: hidden;
    margin-left: 8px;
    border: none;
    box-sizing: border-box;
    padding: 20px;
  }
  .expert_list_item:last-child {
    margin-right: 8px;
  }
  .expert_list_item_image {
    width: 130px;
    height: 130px;
    object-fit: cover;
    border-radius: 80px;
    margin-bottom: 20px;
  }

  .expert_list_item_title {
    font-size: 24px;
    font-weight: var(--brand-font-weight-400);
    font-family: var(--brand-font-family-title);
    color: var(--brand-primary-white-color);
  }
  .expert_list_item_text {
    font-size: 14px;
    font-weight: var(--brand-font-weight-medium);
    font-family: var(--brand-font-family-text);
    color: var(--brand-primary-white-color);
    line-height: 140%;
    margin-top: 4px;
  }

  .custom_carousel_wrapper_style {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .expert_btn {
    margin: auto;
  }

  @media (min-width: 768px) {
    max-width: 90%;
    margin: auto;
    margin-top: 100px;

    .expert_title {
      font-size: 60px;
      text-align: center;
    }

    .expert_list_item {
      min-width: 360px;
      border-radius: 30px;
      padding: 30px;
    }

    .expert_text {
      margin: auto;
      margin-top: 12px;
      max-width: 960px;
      text-align: center;
      font-size: 20px;
    }

    .expert_list_item_image {
      width: 150px;
      height: 150px;
      margin-bottom: 30px;
    }

    .expert_list_item_title {
      font-size: 28px;
    }

    .expert_list_item_text {
      font-size: 18px;
    }

    .expert_list_item {
      margin-left: 20px;
    }

    .expert_list_item:last-child {
      margin-right: 20px;
    }

    .carousel-nav-button {
      background-color: black;
      color: white;
    }
    .custom_carousel_wrapper_style {
      margin-top: 60px;
      margin-bottom: 60px;
    }

    .expert_list_item:first-child {
      margin-left: 0px;
    }
    .expert_list_item:last-child {
      margin-right: 0px;
    }
  }
  @media (min-width: 1125px) {
    max-width: 1120px;
  }
`
