import Responsive from '../../../HOC/Responsive'
import OptimizedImage from '../../../common/OptimizedImage'
import RatingStars from '../../../common/RatingStars/ratingStars'
import {StyledReview} from './CustomerReviews.styles'

function CustomerReview({
  rating,
  reviewTitle,
  reviewBody,
  author,
  age,
  image,
  isMobile,
}) {
  const {url, altText} = image
  const starRatingSize = isMobile ? 17 : 26
  return (
    <StyledReview>
      <div className="right">
        <OptimizedImage
          source={url}
          aspectWidth="308"
          aspectHeight="308"
          desktopViewWidth="THIRD"
          mobileViewWidth="THIRD"
          altText={altText}
          loading="lazy"
          customClassName="review-image"
        />
      </div>
      <div className="left">
        <RatingStars
          rating={rating}
          height={starRatingSize}
          width={starRatingSize}
          blendWithBackground
        />
        <h4 className="review-title">{reviewTitle}</h4>
        <div className="review-body">{reviewBody}</div>
        <div className="review-footer">
          <span>{author}, </span>
          <span>{age}</span>
        </div>
      </div>
    </StyledReview>
  )
}

export default Responsive(CustomerReview)
