import styled from 'styled-components'
import {FILTER_CONSTANTS} from './constants'

export const StyledFilterContainer = styled.div`
  display: flex;
  margin: 0 15px 16px 15px;

  @media screen and (min-width: 768px) {
    margin: 0;
  }
`

export const StyledFilterDropDown = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 7px 16px;
  margin-right: 0;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid var(--brand-primary-light-divider-color);
  cursor: pointer;
  width: ${({noOfFilters}) =>
    `calc((100vw - 30px - ((${noOfFilters} - 1)*8px)) / ${noOfFilters})`};
  margin-right: ${({isLastChild}) => (isLastChild ? '0' : '8px')};

  .label {
    font-size: 16px;
    font-weight: var(--brand-font-weight-normal);
    line-height: 24px;
    color: var(--brand-light-secondary-text-color);
    text-align: center;
    text-transform: lowercase;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  @media screen and (min-width: 768px) {
    border: 1px solid var(--brand-neutral-text-color);
    height: 64px;
    width: ${({noOfFilters}) =>
      `calc((100vw -((${noOfFilters} - 1)*8px)) / ${noOfFilters})`};

    .label {
      font-size: 18px;
      line-height: 27px;
    }
  }
`
export const StyledFilterModalContainer = styled.div`
  > * .filter-modal {
    padding: 0px;
  }
  .filter-close-button{
    top: -56px;
  }
  @media (min-width: 768px) {
    .filter-modal {
      padding: 32px 0 0 0;
      width: 560px;
      border-radius: 16px;
    }
`
export const StyledOption = styled.div`
  padding: 14px 16px;
  font-size: 14px;
  font-weight: var(--brand-font-weight-normal);
  line-height: 21px;

  @media (min-width: 768px) {
    padding: 0 0;
    margin-bottom: 36px;
    font-size: 18px;
    font-weight: var(--brand-font-weight-normal);
    line-height: 27px;
    &:first-child {
      padding: 0;
    }
  }
`
export const StyledFilterModalHeader = styled.div`
  border-bottom: 1px solid var(--brand-primary-light-divider-color);
  color: var(--brand-primary-black-color);
  padding: 16px;
  font-size: 14px;
  font-weight: var(--brand-font-weight-bold);
  line-height: 21px;
  @media (min-width: 768px) {
    font-size: 26px;
    line-height: 39px;
    padding: 0 0 24px 0;
    border: none;
  }
`

export const StyledFilterModalFooter = styled.div`
  display: flex;
`
export const StyledFilterModalFooterButton = styled.button`
  border: none;
  background-color: var(--brand-primary-white-color);
  border-top: 1px solid var(--brand-primary-light-divider-color);
  padding: 16px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${({ctaType}) =>
    ctaType === FILTER_CONSTANTS.CLEAR_OPTION
      ? 'var(--brand-light-error-color)'
      : 'var(--brand-primary-text-color)'};
  font-size: 14px;
  font-weight: var(--brand-font-weight-bold);
  line-height: 21px;
  &:last-child {
    border-left: 1px solid var(--brand-primary-light-divider-color);
  }
  &:disabled {
    opacity: 0.5;
  }

  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 27px;
    border-bottom-right-radius: 16px;
    &:first-child {
      border-bottom-left-radius: 16px;
    }
  }
`
export const StyledImageConcernVariantContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  gap: 8px;
  justify-content: space-between;

  @media (min-width: 768px) {
    gap: 16px;
    padding: 0;
  }
`

export const StyledImageConcernVariant = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  outline: none;
  padding: 0;
  border-radius: 8px;
  width: calc((100vw - 40px) / 2);
  height: 136px;
  border: ${({isClicked}) =>
    isClicked ? '2px solid var(--category-primary-color)' : 'none'};

  &:focus {
    outline: none;
    box-shadow: none !important;
  }

  &:disabled {
    opacity: 0.5;
  }

  @media (min-width: 768px) {
    width: 240px;
  }

  .each-concern-option-image-container {
    width: 136px;
    height: 109px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px 16px 0px 0px;
    overflow: hidden;

    .concern-image {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .each-concern-option-label {
    width: 100%;
    padding: 8px 14px;
    font-weight: var(--brand-font-weight-normal);
    font-size: 14px;
    line-height: 21px;
    color: ${({isClicked}) =>
      isClicked
        ? 'var(--category-primary-color)'
        : 'var(--brand-primary-text-color)'};
    background-color: var(--brand-light-bg-color);
    border-radius: 0px 0px 8px 8px;
    text-align: center;
    overflow: hidden;
    white-space: break-spaces;
    text-overflow: ellipsis;
  }
`

export const StyledModalContentWrap = styled.div`
  @media (min-width: 768px) {
    padding: 0 32px;
    margin-bottom: 32px;
  }
`
