import React from 'react'
import noop from 'lodash/noop'

import FilterDropDown from './FilterDropDown'
import {StyledFilterContainer} from './styles'
import {IFilter, IFilterDropDownBoxProps} from './FilterDropDownBox.types'

function FilterDropDownBox(props: IFilterDropDownBoxProps) {
  const {
    filters = [],
    options = [],
    filtersCta = [],
    appliedConcernFilters = {},
  } = props.state
  const {onFilterOptionSelect = noop, onClearFilter = noop} = props.actions

  if (!filters || !filters.length) return null
  return (
    <StyledFilterContainer data-testid="filter-dropdown-box">
      {filters?.map((filter: IFilter, index: number) => {
        const mappedOptions = options?.find(
          (optionObj) => optionObj?.key === filter?.key
        )
        return (
          <FilterDropDown
            key={filter?.key}
            filter={filter}
            options={mappedOptions?.values || []}
            filtersCta={filtersCta}
            appliedFilter={appliedConcernFilters[filter?.key]}
            noOfFilters={filters.length}
            isLastChild={index === filters.length - 1}
            onFilterOptionSelect={onFilterOptionSelect}
            onClearFilter={onClearFilter}
          />
        )
      })}
    </StyledFilterContainer>
  )
}

export default FilterDropDownBox
