import {StyledWrapper} from './styles'
import QualityStory from '../QualityStory/QualityStory'
import isEmpty from 'lodash/isEmpty'
import Tick from './assets/Tick'
import {withCustomErrorBoundary} from '../../../../utils/withCustomErrorBoundary'

const QualityCheck = (props) => {
  let {points = []} = props.state || {}

  //checking for null and undefined
  if (isEmpty(points)) {
    points = []
  }
  return (
    <StyledWrapper>
      <QualityStory {...props} className="our_story">
        <div className="quality_container">
          {points.map((point, index) => {
            return (
              <div key={`quality-check-${index}`} className="quality_wrapper">
                <Tick />
                <div>{point}</div>
              </div>
            )
          })}
        </div>
      </QualityStory>
    </StyledWrapper>
  )
}

export default withCustomErrorBoundary(QualityCheck, 'QualityCheck')
