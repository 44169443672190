import styled from 'styled-components'

export const ActionButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 32px 16px 24px 16px;
  gap: 8px;

  .subheading-text {
    font-size: 14px;
    line-height: 21px;
    font-weight: var(--brand-font-weight-normal);
    color: var(--brand-light-secondary-text-color);
    text-align: left;
  }

  @media screen and (min-width: 768px) {
    margin: 32px 0px 24px 0px;
    gap: 16px;
    .subheading-text {
      font-size: 18px;
      line-height: 27px;
    }
  }
`

export const StyledCTAWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (min-width: 768px) {
    flex-direction: row-reverse;
    gap: 16px;
  }
`

export const StyledCTA = styled.button`
  box-sizing: border-box;
  padding: 11px 16px;
  background-color: ${({isPrimary}) =>
    isPrimary
      ? 'var(--brand-primary-color)'
      : 'var(--brand-primary-white-color)'};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({isPrimary}) =>
    isPrimary
      ? 'var(--brand-primary-white-color)'
      : 'var(--brand-primary-color)'};
  font-family: var(--brand-font-family-text);
  font-weight: var(--brand-font-weight-semiBold);
  font-size: 14px;
  line-height: 21px;
  border: ${({isPrimary}) =>
    isPrimary ? 'none' : '1px solid var(--brand-primary-color)'};
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  outline: none;
  position: relative;
  overflow: hidden;
  -webkit-highlight: none;
  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: none;
    box-shadow: none !important;
  }

  .font-awesome-icon {
    margin-left: 8px;
  }

  .tag-text {
    background: ${({tagColor}) => tagColor};
    margin-left: 10px;
    color: var(--brand-primary-black-color);
    border-radius: 16px;
    width: auto;
    padding: 2px 8px;
    font-size: 10px;
    line-height: 15px;
    font-weight: var(--brand-font-weight-normal);
  }

  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 27px;
    padding: 10px 24px;
    border-radius: 8px;
    height: 56px;
    width: ${({noOfButtons}) =>
      `calc((100vw - ((${noOfButtons} - 1)*16px)) / ${noOfButtons})`};
    .font-awesome-icon {
      margin-left: 16px;
    }
    .tag-text {
      font-size: 14px;
      line-height: 21px;
    }
  }
`
