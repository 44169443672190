import React, {useState, useMemo, useCallback} from 'react'
import noop from 'lodash/noop'

import {ArrowHead} from '../Arrows/ArrowHead'
import FilterSelectorModal from './FilterSelectorModal'
import {StyledFilterDropDown} from './styles'
import {IFilterDropDownProps, IFilter, IOption} from './FilterDropDownBox.types'

const FilterDropDown = (props: IFilterDropDownProps) => {
  const {
    filter,
    options = [],
    filtersCta = [],
    noOfFilters,
    isLastChild = false,
    appliedFilter,
    onFilterOptionSelect = noop,
    onClearFilter = noop,
  } = props || {}
  const {label = '', key = ''} = filter || {}

  const [isSelectorOpen, setIsSelectorOpen] = useState(false)

  const isFilterApplied = useMemo(() => {
    if (appliedFilter) {
      return Object.keys(appliedFilter).length > 0
    }
    return false
  }, [appliedFilter])

  const handleFilterClick = useCallback(() => {
    setIsSelectorOpen(!isSelectorOpen)
  }, [isSelectorOpen])

  const handleSelectFilter = useCallback(
    (filter: IFilter, filterOption: IOption) => {
      onFilterOptionSelect(filter, filterOption)
    },
    [onFilterOptionSelect]
  )

  const handleModalClose = useCallback(() => {
    setIsSelectorOpen(false)
  }, [])

  return (
    <>
      <StyledFilterDropDown
        noOfFilters={noOfFilters}
        isLastChild={isLastChild}
        onClick={handleFilterClick}
        key={key}
      >
        <div className="label">{appliedFilter?.label || label}</div>
        <ArrowHead direction={isSelectorOpen ? 'up' : 'down'} />
      </StyledFilterDropDown>

      <FilterSelectorModal
        filter={filter}
        options={options}
        filtersCta={filtersCta}
        isModalOpen={isSelectorOpen}
        onClose={handleModalClose}
        applyFilter={handleSelectFilter}
        removeFilter={onClearFilter}
        isFilterApplied={isFilterApplied}
      />
    </>
  )
}

export default FilterDropDown
