import styled from 'styled-components'

export const StyledWrapper = styled.div`
  width: 100%;
  padding: 15px;
  margin: auto;
  margin-top: 60px;
  max-width: 1120px;

  .video_title {
    font-size: 28px;
    font-weight: var(--brand-font-weight-400);
    font-family: var(--brand-font-family-title);
  }

  .video_text {
    font-size: 14px;
    font-weight: var(--brand-font-weight-400);
    font-family: var(--brand-font-family-text);
    margin-top: 4px;
  }

  .video_container {
    margin-top: 30px;
    height: 220px;
  }

  @media (min-width: 768px) {
    margin-top: 80px;
    .video_title {
      font-size: 60px;
      text-align: center;
    }
    .video_text {
      margin: auto;
      margin-top: 12px;
      font-size: 20px;
      text-align: center;
      max-width: 855px;
    }
    .video_container {
      margin-top: 60px;
      height: 500px;
    }
  }
`
